import { Component, OnInit, NgModule, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl , AbstractControl ,ValidationErrors , ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriberService } from '@app/services/subscriber.service';
import {Subject} from 'rxjs'
import { first, takeUntil } from 'rxjs/operators';
import { PasswordStrengthValidator } from "@app/password-strength.validators";
import { AlertService } from '@app/services/alert.service';

function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}// end confirmedvalidator

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  isMobileOtpMatch = true;
  email: string;
  verificationCode: string;
  typePassword: boolean = false;
  typeConfimPass: boolean = false;

  unsubscribe$ = new Subject()

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private subscriberService: SubscriberService) { }

  ngOnInit(): void {

    this.email = this.route.snapshot.params['email'];
    this.verificationCode = this.route.snapshot.params['otp'];
    this.form = this.formBuilder.group({
      password: ['',  Validators.compose([
        Validators.required, Validators.minLength(8), Validators.maxLength(20), PasswordStrengthValidator])],
      cnf_password: ['', Validators.required],
      verificationCode: [this.verificationCode, Validators.required],
      email: [this.email, Validators.required],
    }, {
      validator: ConfirmedValidator('password', 'cnf_password')
    });
  }// end ngOnInit

  // convenience getter for easy access to form fields
  get rpFormControl() { return this.form.controls; }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public togglePassword(type): void {
    if(type === "password")
      this.typePassword = !this.typePassword;
    else
      this.typeConfimPass = !this.typeConfimPass;
  }


  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
        return;
    }
    this.subscriberService.resetPassword(this.form.getRawValue())
      .pipe(first(), takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        //this.formSubmitted = false;
        if(data.status)  {
          this.alertService.success(data.message, { keepAfterRouteChange: true });
          this.submitted = false;
          this.router.navigate(['/signin/']);
        } else{
          this.alertService.error(data.message);
          this.loading = false;
        }
      });

   }// end function onsubmit

   ngOnDestroy(): void {
       this.unsubscribe$.next()
       this.unsubscribe$.complete()
   }



}
