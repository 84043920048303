import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';

@Injectable({ providedIn: 'root' })
export class DynamicContentService {
  //private isLoggedIn = new BehaviorSubject(false);
  //isLoggedIn$ = this.isLoggedIn.asObservable();

  private Source = new BehaviorSubject('');
  contenttype$ = this.Source.asObservable();

  constructor(private appService: AppService) {}

  private Register() {
    return () =>
      import('@app/component/register/register.component').then(
        m => m.RegisterComponent
      );
  }
 

  private Nothing() {
   // return () =>
   //   import('@app/modules/general/not-found/not-found.component').then(
   //     m => m.NotFoundComponent
   //   );
  }

  

  //login() {
  //  this.isLoggedIn.next(true);
 // }

  //logout() {
   // this.isLoggedIn.next(false);
  //}

  changeComponent(contenttype: string) {
    this.Source.next(contenttype)
  }
  content:any;
  loadComponent(vcr: ViewContainerRef, contenttype: string) {
  vcr.clear();
    
    if(contenttype == 'register') {  this.content = this.Register();  } 
    else                          {  this.content = this.Nothing();   }

    return this.appService.forChild(vcr, {
      loadChildren:this.content
      //loadChildren: isLoggedIn ? this.clientProfile() : this.guestProfile()
    });
    
  }
}
