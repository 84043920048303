<div class="px-4 px-lg-0">
    <div class="pb-5">
      <div class="container">
        <div class="row py-5 p-4 bg-white rounded shadow-sm">
          <div class="col-lg-6">
             <dynamic-content-container></dynamic-content-container>
          </div>
          
          <div class="col-lg-6">
            <p class="font-italic mb-4">
              <strong><a routerLink='/cart/{{selectedPackage?.id}}/{{pacakageType}}' skipLocationChange replaceUrl class="button">Back</a></strong>  
            </p>
            <div class="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold" >Order summary </div>
            <div class="p-4">
              <p class="font-italic mb-4"><strong>{{selectedPackage?.packageName}}</strong></p>
              <ul class="list-unstyled mb-4">
                <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Order Subtotal </strong><strong>{{selectedPackage?.cost | number : '1.2-2'}}</strong></li>
                <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Shipping and handling</strong><strong>0.00</strong></li>
                
                <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Addons</strong>
                  <ul class="addon" >
                    <li *ngFor="let addon of addons; let i = index">{{addon.addonName}} <strong>{{addon.cost}}</strong></li>
                  </ul>
                </li>


                <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Tax</strong><strong>0.00</strong></li>
                <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Total</strong>
                  <h5 class="font-weight-bold">{{selectedPackage?.cost | number : '1.2-2'}}</h5>
                </li>
              </ul>
              <!--<a href="#" class="btn btn-dark rounded-pill py-2 btn-block">Procceed to checkout</a>-->
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>