import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductPackagesComponent } from '@app/component/product-packages/product-packages.component';
import { PackageCartComponent } from '@app/component/package-cart/package-cart.component';
import { RegisterSubscriberComponent} from '@app/component/register-subscriber/register-subscriber.component';
import { PaymenyProcessComponent } from '@app/component/payment-process/payment-process.component';
import { PaymentSuccessComponent } from '@app/component/payment-success/payment-success.component';
import { LoginComponent } from '@app/component/login/login.component';
import { ForgotPasswordComponent } from '@app/component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/component/reset-password/reset-password.component';
import { TestThemeComponent } from './component/test-theme/test-theme.component';


import { AuthGuard  } from './helper';

import { NotFoundComponent } from './modules/general/not-found/not-found.component';
const routes: Routes = [
  { path: '', component: ProductPackagesComponent  },
  { path: 'cart/:id/:type', component: PackageCartComponent  } ,
  { path: 'signup/:id/:type', component: RegisterSubscriberComponent  },
  { path: 'payment-process/:id', component: PaymenyProcessComponent  } ,
  { path: 'payment-success/:id', component: PaymentSuccessComponent  },
  { path: 'signin', component: LoginComponent  } ,
  { path: 'forgot-password', component: ForgotPasswordComponent  } ,
  { path: 'reset-password/:email/:otp', component: ResetPasswordComponent  } ,
  { path: 'test-theme', component: TestThemeComponent  } ,
  {
    path: '',
    loadChildren: () => import('./modules/admin/admin.module')
      .then(mod => mod.AdminModule) , canActivate: [AuthGuard]
  } ,
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module')
      .then(mod => mod.SettingsModule) , canActivate: [AuthGuard]
  } ,
  {
    path: 'transified',
    loadChildren: () => import('./modules/transified/transified.module')
      .then(mod => mod.TransifiedModule) , canActivate: [AuthGuard]
  } ,
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
