import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductPackageService } from './../../services/product-package.service';
import { DynamicContentService } from '@app/services/dynamic-content.service';
import { Package } from '@app/models/package';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-register-subscriber',
  templateUrl: './register-subscriber.component.html',
  styleUrls: ['./register-subscriber.component.css']
})
export class RegisterSubscriberComponent implements OnInit, OnDestroy {
  pacakageId:string;
  pacakageType:string;
  packages : any;
  selectedPackage;
  addons : any;
  response : any;
  selectedAddons :any;

  unsubscribe$ = new Subject()

  constructor(private route: ActivatedRoute , private ProductPackageService: ProductPackageService
    , private dynamicContentService : DynamicContentService) { }

  ngOnInit(): void {
    this.pacakageId = this.route.snapshot.paramMap.get('id');
    this.pacakageType = this.route.snapshot.paramMap.get('type');
      this.ProductPackageService.getSelectedPackage(this.pacakageId, localStorage.getItem('selectedAddons')).pipe(takeUntil(this.unsubscribe$))
              .subscribe((data: any) =>{
                  //this.packages = JSON.parse(data);
                  this.response = data;
                  this.packages = this.response.packages;
                  this.addons   = this.response.addons;

                  for(let i=0;i < this.packages.length;i++)
                  {
                    if(this.packages[i].id == this.pacakageId)
                    {
                      this.selectedPackage = this.packages[i];
                      localStorage.setItem('packageId', this.packages[i].id);
                    }
                  }//  end for

                });

    this.dynamicContentService.changeComponent('register');
  }// end if ngOnInIt

  ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete()
  }



}
