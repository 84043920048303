<p class="font-italic mb-4"><strong>Sign Up Form</strong></p>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    
    <div class="input-group mb-4 ">
      <input type="text" placeholder="Company name" formControlName="subscribername"  class="form-control border rounded-pill p-2" [ngClass]="{ 'is-invalid': submitted && f.subscribername.errors }">
      <div *ngIf="submitted && f.subscribername.errors" class="invalid-feedback">
        <div *ngIf="f.subscribername.errors.required">Subscriber name is required</div>
      </div>
    </div>
    
    <div class="input-group mb-4">
        <input type="text" placeholder="Owner name" formControlName="ownername" aria-describedby="button-addon3" class="form-control border rounded-pill p-2"
        [ngClass]="{ 'is-invalid': submitted && f.ownername.errors }">
        <div *ngIf="submitted && f.ownername.errors" class="invalid-feedback">
            <div *ngIf="f.ownername.errors.required">Owner name is required</div>
          </div>
    </div>
    <div class="input-group mb-4">
        <input type="text" placeholder="Gst number" formControlName="gstnumber" aria-describedby="button-addon3" class="form-control border rounded-pill p-2"
        [ngClass]="{ 'is-invalid': submitted || (f.gstnumber.errors && f.gstnumber.touched) }" maxlength="15">
        <div *ngIf="(submitted && f.gstnumber.errors) || (f.gstnumber.errors && f.gstnumber.touched)" class="invalid-feedback">
            <div *ngIf="f.gstnumber.errors.pattern">Gst number must be a valid Gst Number</div>
            <div *ngIf="f.gstnumber.errors.isGstUnique">This Gst Number has been already exist</div>
          </div>
    </div>
    <div class="input-group mb-4">
        <input type="text" placeholder="Enter 10 digit mobile number" formControlName="mobilenumber" 
        aria-describedby="button-addon3" class="form-control border rounded-pill p-2 col-md-6"
        [ngClass]="{ 'is-invalid': submitted || (f.mobilenumber.errors && f.mobilenumber.touched) }" maxlength="10">
        <button type="button" class="btn btn-dark rounded-pill py-2 btn-block col-md-3" (click)="sendOtp('mobile');"
        [disabled]="!f.mobilenumber.valid || enabled">{{mobileopttext}}</button>
        <input type="text" placeholder="Mobile Otp" formControlName="mobileotp" 
        aria-describedby="button-addon3" class="form-control border rounded-pill p-2 col-md-3"
        [ngClass]="{ 'is-invalid': submitted && f.mobileotp.errors }" maxlength="4" >
          <div *ngIf="(submitted && f.mobilenumber.errors) || (f.mobilenumber.errors && f.mobilenumber.touched)" class="invalid-feedback">
            <div *ngIf="f.mobilenumber.errors.required">Mobile number is required</div>
            <div *ngIf="f.mobilenumber.errors.pattern">Mobile number must be a valid Indian mobile number</div>
            <div *ngIf="f.mobilenumber.errors.isPhoneUnique">This phone has been registered already</div>
          </div>
          <div *ngIf="(submitted && f.mobileotp.errors) && f.mobileotp.errors" class="invalid-feedback">
            <div *ngIf="f.mobileotp.errors.required">Mobile OTP is required</div>
            <div *ngIf="f.mobileotp.errors.pattern">Mobile OTP not valid</div>
            <div *ngIf="f.mobileotp.errors.notmValidOtp">Mobile OTP not valid</div>
          </div>
    </div>
    <div class="input-group mb-4">
        <input type="email" placeholder="EmailId" formControlName="emailid" aria-describedby="button-addon3" class="form-control border rounded-pill p-2 col-md-6"
        [ngClass]="{ 'is-invalid': submitted || (f.emailid.errors && f.emailid.touched ) }">
        <button type="button" class="btn btn-dark rounded-pill py-2 btn-block col-md-3" (click)="sendOtp('email');"
        [disabled]="!f.emailid.valid">Sent OTP</button>
        <input type="text" placeholder="Email Otp" formControlName="emailotp" 
        aria-describedby="button-addon3" class="form-control border rounded-pill p-2 col-md-3"
        [ngClass]="{ 'is-invalid': submitted && f.emailotp.errors }" maxlength="4" >
        <div *ngIf="(submitted && f.emailid.errors) || (f.emailid.errors && f.emailid.touched )" class="invalid-feedback">
          <div *ngIf="f.emailid.errors.required">Email is required</div>
          <div *ngIf="f.emailid.errors.email">Email must be a valid email address</div>
          <div *ngIf="f.emailid.errors.isEmailUnique">This email has been registered already</div>
        </div>
        <div *ngIf="submitted && f.emailotp.errors" class="invalid-feedback">
          <div *ngIf="f.emailotp.errors.required">Email OTP is required</div>
          <div *ngIf="f.emailotp.errors.pattern">Email OTP not valid</div>
          <div *ngIf="f.emailotp.errors.notmValidOtp">Email OTP not valid</div>
        </div>
    </div>
    <div class="input-group mb-4">
        <textarea placeholder="Address" formControlName="address" cols="30" rows="2" class="form-control border rounded-pill p-2"
        [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
            <div *ngIf="f.address.errors.required">Address is required</div>
          </div>
    </div>
    <div class="input-group mb-4">
        <select formControlName="state" class="form-control border rounded-pill p-2" [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
        (click)="onStateChange($event.target.value)">
          <option value="">--Select State--</option>
          <option *ngFor="let state of states" value="{{state.id}}">{{state.stateName}}</option>
        </select>  
        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
            <div *ngIf="f.state.errors.required">State is required</div>
        </div>
    </div>
    <div class="input-group mb-4">
      <select formControlName="city" class="form-control border rounded-pill p-2" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
        <option value="">--Select City--</option>
        <option *ngFor="let city of cities" value="{{city.id}}">{{city.cityName}}</option>
      </select>
      <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
          <div *ngIf="f.city.errors.required">City is required</div>
        </div>
  </div>
    <div class="input-group mb-4">
        <input type="text" placeholder="PinCode" formControlName="pincode" aria-describedby="button-addon3" class="form-control border rounded-pill p-2">
    </div>

    <div class="input-group mb-4">
        <input type="password" placeholder="Password" formControlName="password"  class="form-control border rounded-pill p-2" 
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>  
      </div>
    
      <div class="input-group mb-4">
        <input type="password" placeholder="Confirm Password" formControlName="cnf_password"  class="form-control border rounded-pill p-2" 
        [ngClass]="{ 'is-invalid': submitted && f.cnf_password.errors }">
          <div *ngIf="submitted && f.cnf_password.errors" class="invalid-feedback">
            <div *ngIf="f.cnf_password.errors.required">Confirm Password is required</div>
            <div *ngIf="f.cnf_password.errors.confirmedValidator">Password and Confirm Password must be match.</div>
          </div>  
    </div>
    
    <div class="p-4">
        <input type="hidden" formControlName="packageId">
        <button type="submit" class="btn btn-dark rounded-pill py-2 btn-block">SignUp</button>
    </div>
</form>