import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl ,ValidatorFn , AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SubscriberService } from '@app/services/subscriber.service';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgetForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  vsubmitted = false;
  returnUrl: string;
  second: number;
  mobileopttext: any = "Sent Otp";
  enabled = false;
  hideShowForm1: boolean = false;
  hideShowForm2: boolean = true; // hidden by default
  otpDetail:any;  motp;
  unsubscribe$ = new Subject()

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private subscriberService: SubscriberService,
      private alertService: AlertService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
    });

    this.otpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      verificationCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(6)] ]
    });
  }// end ngOnInit

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  // convenience getter for easy access to form fields
  get forgetFormControl() { return this.forgetForm.controls; }
  get otpFormControl() { return this.otpForm.controls; }

  onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
        return;
    }
    this.loading = true;
    this.forgetPass();
  }// end onSubmit

  private forgetPass() {
    let email = this.forgetFormControl.email.value;
    this.subscriberService.forgetPass(this.forgetForm.getRawValue())
    .pipe(first(), takeUntil(this.unsubscribe$))
    .subscribe((data: any) => {
      if(data.status)  {
        this.alertService.success(data.message, { keepAfterRouteChange: true });
        this.submitted = false;
        this.hideShowForm1 = true;
        this.hideShowForm2 = false;
        this.loading = false;
        this.otpFormControl.email.setValue(email);
        //this.router.navigate(['/reset-password/'+email], { skipLocationChange: true });
      } else{
        this.alertService.error(data.message);
        this.loading = false;
      }
    });
  }

  onOtpSubmit() {
    this.vsubmitted = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.otpForm.invalid) {
        return;
    }
    this.loading = true;
    this.checkOtp();
  }// end onSubmit


  checkOtp() {
    let vCode = this.otpFormControl.verificationCode.value;
    let email = this.otpFormControl.email.value;
    this.subscriberService.OtpValid(this.otpForm.getRawValue())
      .pipe(first(), takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.alertService.clear();
        if(data.status)  {
          this.submitted = false;
          this.loading = false;
          this.router.navigate(['/reset-password/'+email+'/'+vCode], { skipLocationChange: true });
        } else{
          this.alertService.error(data.message);
          this.loading = false;
          /*this.hideShowForm1 = false;
          this.hideShowForm2 = true;*/
        }
      });

  }// end checkOtp


}
