import { Component , OnInit  } from '@angular/core';
import { Router, ActivatedRoute , RouterEvent , NavigationEnd } from '@angular/router';
import { SubscriberService } from '@app/services/subscriber.service';
import { first , filter } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { CommonService } from '@app/services/common.service';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
//import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'transified-web';
  faCoffee = faCoffee;

  //route: string;
  constructor(private router: Router ,private route: ActivatedRoute , private subscriberService : SubscriberService, private location: LocationStrategy, private commonService: CommonService){
    // preventing back button in browser
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    var i =0;
    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        //this.router.navigated = false;
        //console.log(event.id+' === 1 &&    '+event.url+' === '+event.urlAfterRedirects+'   '+event);
      }
      if ( event.id === 1 && event.url === event.urlAfterRedirects ) {
        // here your code when page is refresh
        let localurl = this.subscriberService.getcurrentPage;
        let routePath = event.url;
        let segments = event.url.split('/');

        if(segments.length > 2 ){
          segments  = segments.slice(0, 2);
          routePath = segments.join("/");
          if(routePath == "/settings"){
            routePath = event.url;
          }
        }
        // console.log(routePath);
        this.commonService.activeMenu(routePath);
        this.commonService.navigate(routePath);
        this.commonService.transified(routePath);

      }else {
        //this.subscriberService.currentPageValue(this.router.url);
      }
    })

    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('user');
        if(token == undefined) {
         // Perform logout
         //Navigate to login/home
          this.router.navigate(['/signin']);
        }
      }
    });
  }
}
