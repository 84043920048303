import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriberService } from '@app/services/subscriber.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css']
})
export class PaymenyProcessComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  subscriberid;
  emailOtp :string;
  mobileOtp : string;

  unsubscribe$ = new Subject()

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private subscriberService: SubscriberService) { }

  ngOnInit(): void {
    this.route.params.subscribe(val => {
      this.subscriberid = this.route.snapshot.paramMap.get('id');
    });

    this.form = this.formBuilder.group({

    });
  }//end ngOnInit


  get f() { return this.form.controls; }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    //if (this.form.invalid) {
      //  return;
    //}
    this.subscriberService.paymentProcess(this.subscriberid)
    .pipe(first(), takeUntil(this.unsubscribe$))
    .subscribe(
        (data : any) => {
            console.log(data);
            //this.alertService.success('Branch added successfully', { keepAfterRouteChange: true });
            this.router.navigate(['/payment-success/'+data.id] , { skipLocationChange: true });
        },
        error => {
            console.log(error);
            //this.alertService.error(error);
            this.loading = false;
        });

  }// end onSubmit

  ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete()
  }

}
