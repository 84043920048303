import { Injectable } from '@angular/core';
import { BehaviorSubject , Observable } from 'rxjs';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Package } from './../models/package'

@Injectable({
  providedIn: 'root'
})
export class ProductPackageService {

  private packageSubject: BehaviorSubject<Package>;  
  public package$: Observable<Package>;  

  constructor(private http: HttpClient) {  }

  getAll() {
    return this.http.get<{data :Package[]}>(`${environment.apiUrl}/packages`);
  }// end getall

  getPackageDetail(id: BigInteger){

  }

  getSelectedPackage(pacakageId, addons,noOfBranches="1",noOfMonths="1"){
  	return this.http.post(`${environment.apiUrl}/package-detail`,{"pacakageId":pacakageId ,"addons" : addons,"noOfBranches" : noOfBranches,"noOfMonths" : noOfMonths } );
  }

}// end class
