<div class="login-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-11 signin-bg-img">
        <div class="w-100 float-left">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-6  d-lg-block d-none">
              <div class="logo-dv w-100 float-left">
                <a routerLink="../signin"> <img src="../../../assets/images/logo.png" class="img-fluid" alt="logo"></a>
              </div><!--logo-dv-->
            </div>
          </div><!--row-->
          <div class="row">
            <div class="col-sm-11 col-10 offset-1 col-md-9 offset-md-2 offset-lg-1 col-lg-5 offset-sm-1">
              <div class="login-form w-100  d-flex">
                <div class="w-100 float-left">
                   <div class="logo-dv w-100 float-left pl-0">
                      <div class="row">
                        <div class="col-8 offset-2 mb-5 d-block d-lg-none">
                          <img src="../../../assets/images/logo.png" class="img-fluid w-100 " alt="logo">
                        </div>
                      </div>
                    </div><!--logo-dv-->
                  <div class="form-heading w-100 float-left text-center mb-5">
                    <h5>Sign In</h5>
                  </div>
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">Email Address</label>
                        <input type="text" formControlName="username" appAutofocus class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Email is required</div>
                            <div *ngIf="f.username.errors.pattern">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">Password</label>
                        <div class="passinpt">
                          <input type="{{ typePassword ? 'text' : 'password' }}" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                          <a (click)="togglePassword();" *ngIf="typePassword" [ngClass]="{ 'password-type': submitted && f.password.errors }"><img src="assets/images/show-pass.png"></a>
                          <a (click)="togglePassword();" *ngIf="!typePassword" [ngClass]="{ 'password-type': submitted && f.password.errors }"><img src="assets/images/hide-pass.png"></a>
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required">Password is required</div>
                          </div>
                        </div><!--passinpt-->
                    </div>
                    <div class="form-group">
                        <a routerLink="../forgot-password" class="frgt-passord">Forgot Password?</a>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-lg btn-block col-12 blue-button" type="submit" [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Sign In</button>
                    </div>
                    </form>
                </div>
              </div><!--login-form-->
            </div><!--col-7-->
          </div><!--end-row-->
        </div>
      </div>
    </div><!--row-->
  </div>
</div>
