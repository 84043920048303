import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrfdNotificationService {

  public notification: Observable<any>;
  private notificationSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get(`${environment.apiUrl}/notification-list`);
  }

  getCount() {
    return this.http.get(`${environment.apiUrl}/notification-count`);
  }

  markNotification(id) {
    return this.http.get<[]>(`${environment.apiUrl}/mark-notification/${id}`);
  }

  readNotification(id) {
    return this.http.get<[]>(`${environment.apiUrl}/read-notification/${id}`);
  }
}
