import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductPackageService } from './../../services/product-package.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SubscriberService } from '@app/services/subscriber.service';
import { FormBuilder, FormGroup, Validators, FormControl , AbstractControl ,ValidationErrors , ValidatorFn } from '@angular/forms';

//'@service/ProductPackageService';
@Component({
  selector: 'product-packages',
  templateUrl: './product-packages.component.html',
  styleUrls: ['./product-packages.component.css']
})
export class ProductPackagesComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = false;
  submitted = false;
  packages : any;
  addons : any;
  response : any;
  noOfBranches;
  noOfMonths;
  selectedAddons : any;
  addonShown: boolean = false ;

  returnUrl: string = 'dashboard';
  returnUrlTrfd: string = 'transified/dashboard';

  unsubscribe$ = new Subject()

  constructor(
    private formBuilder: FormBuilder,
    private ProductPackageService: ProductPackageService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriberService : SubscriberService
  ) {

  }

  ngOnInit(): void {

    const user = this.subscriberService.userValue;
    if(user == undefined) {
         // Perform logout
         //Navigate to login/home
      this.router.navigate(['/signin']);
    } else if(user && user.access_token) {
        if (user.userType == "trfdMgmt" || user.userType == "trfdAdmin" || user.userType == "trfdSupport") {
          this.router.navigate([this.returnUrlTrfd]);
        } else {
          this.router.navigate([this.returnUrl]);
        }
    }


    this.ProductPackageService.getAll().pipe(takeUntil(this.unsubscribe$))
            .subscribe(data =>{
                //console.log('package => ', data);
                this.response = data;
                this.packages = JSON.parse(this.response.packages);
                this.addons = JSON.parse(this.response.addons);
                // localStorage.setItem('selectedAddons', ''); to empty addons
                for(let i=0;i < this.packages.length;i++) {
                  localStorage.setItem('packages', this.packages[i].id);
                }
                this.noOfBranches = localStorage.getItem('noOfBranches')
                this.noOfMonths = localStorage.getItem('noOfMonths')
              });



    this.form = this.formBuilder.group({
      packageId:[localStorage.getItem('packageId')],
      noOfBranches: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)] ],
      noOfMonths: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)] ],
    });

  }

  changeCheckbox(option, event) {
    //console.log(option);
    let getitem = "";
    if (event.target.checked) {
      this.addonShown = true;
      if(localStorage.getItem('selectedAddons') != null){
        getitem = (localStorage.getItem('selectedAddons'));
      }
      getitem = getitem +","+event.target.value;
      localStorage.setItem('selectedAddons', (getitem));
    } else{
      this.addonShown = false;
      if(localStorage.getItem('selectedAddons') != null){
        getitem = localStorage.getItem('selectedAddons');
        let replace = "";
        let find = event.target.value;
        var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        getitem = getitem.replace(new RegExp(escapedFind, 'g'), replace);
        //getitem = getitem.replace(event.target.value, '');
      }
      localStorage.setItem('selectedAddons', (getitem));
    }
    console.log(this.addonShown);
    return this.addonShown;

  }

  toggleCheckBox(elementId){
    /* let selectedAddons = localStorage.getItem('selectedAddons');
    if(selectedAddons != null ) {
      var selectedAddonsArr = selectedAddons.split(',').map(Number);
      for(let i=0;i < selectedAddonsArr.length;i++) {
        if(selectedAddonsArr[i] == elementId) {

          return  true ;
        }
      } // end of for

    } */

  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  get total() {

    var noOfBranches = this.f.noOfBranches.value > 0 ? this.f.noOfBranches.value : 1;
    var noOfMonths   = this.f.noOfMonths.value > 0 ? this.f.noOfMonths.value : 1;
    let packageCost  = this.packages[0].cost;
    let total        = noOfBranches*noOfMonths*packageCost;

    let selectedAddons = localStorage.getItem('selectedAddons');
    if(selectedAddons != null ) {
      var selectedAddonsArr = selectedAddons.split(',').map(Number);
      var uniqueArr = selectedAddonsArr.filter((item, i, ar) => ar.indexOf(item) === i);
      ///console.log(uniqueArr);

      for(let i=0;i < uniqueArr.length;i++) {

        for(let j=0; j < this.addons.length;j++) {
          if(uniqueArr[i] == this.addons[j].id) {
          total = total+ this.addons[j].cost;
          }
        }

      } // end of for

    }

    return total;
  }
  get packageTotal() {

    var noOfBranches = this.f.noOfBranches.value > 0 ? this.f.noOfBranches.value : 1;
    var noOfMonths   = this.f.noOfMonths.value > 0 ? this.f.noOfMonths.value : 1;
    let packageCost  = this.packages[0].cost;
    let total        = noOfBranches*noOfMonths*packageCost;

    return total;
  }



  onSubmit(packageType): void {

    this.submitted = true;
    //console.log(this.form.value);
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    var noOfBranches = this.f.noOfBranches.value;
    var noOfMonths   = this.f.noOfMonths.value;
    var packageId    = localStorage.getItem('packageId');
    localStorage.setItem('noOfBranches', noOfBranches);
    localStorage.setItem('noOfMonths', noOfMonths);

    if(packageType == "Buy"){
      this.router.navigate(["/cart/"+packageId+"/Buy"]);
    } else if(packageType == "Free"){
      this.router.navigate(["/cart/"+packageId+"/Free"]);
    }  else{
      this.router.navigate(["/cart/"+packageId+"/Buy"]);
    }


  }// end function onsubmit

  ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete()
  }

}
