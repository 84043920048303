import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot ,Route, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { SubscriberService } from '@app/services/subscriber.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate  {
    constructor(
        private router: Router,
        private accountService: SubscriberService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        // console.log(user);
        //return false;
        if (user) {
            this.router.events
            .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
            .subscribe(event => {
                if((user.userType == 'subscriber' || user.userType == 'superAdmin' || user.userType == 'staff') && event.url.includes('transified') ){
                    this.router.navigate(['/dashboard']);
                }

                if((user.userType=== 'subscriber' && event.url.includes('/profile-detail')) || (user.userType=== 'subscriber' && event.url.includes('/profile-edit'))){
                  this.router.navigate(['/dashboard']);
                }

                if(user.userType == "staff"){
                    if(event.url.includes('settings')) { // staff can n't access settings
                        this.router.navigate(['/dashboard']);
                    } else if(Number(user.isPrivlleges) !== 1) {
                        const url = event.url.replace('/','').split('-');
                        if(url.length && user.permissions !== "") {
                            const permission = JSON.parse(JSON.stringify(user.permissions)).find( x=> x.scriptName.toLowerCase() === url[0].toLowerCase() );

                            /*const reportPermission = JSON.parse(JSON.stringify(user.permissions)).find( x=> x.scriptName.toLowerCase() === "report" );*/
                            
                            if(event.url.includes('list') && (permission.isAdd == "" && permission.isEdit == "" && permission.isView == "" && permission.isDelete == "" && permission.isCancel == "" && permission.isExport == "")) {
                                this.router.navigate(['/dashboard']);
                            } else if(event.url.includes('list') && (permission.isAdd === "0" && permission.isEdit === "0" && permission.isView === "0" && permission.isDelete === "0" && permission.isCancel === "0" && permission.isExport === "0")) {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('add') && permission.isAdd == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('edit') && permission.isEdit == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('detail') && permission.isView == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('delete') && permission.isDelete == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('cancel') && permission.isCancel == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('export') && permission.isExport == "") {
                                this.router.navigate(['/dashboard']);
                            } else if(event.url.includes('report') && permission?.isVisible == "") {
                                this.router.navigate(['/dashboard']);
                            }else if(event.url.includes('report') && permission?.isVisible === "0") {
                                this.router.navigate(['/dashboard']);
                            }
                        }
                    }
                } else if( user.userType == 'superAdmin' && user.isSetting != '1' && event.url.includes('settings') ){
                    this.router.navigate(['/dashboard']);
                }
            });
            // authorised so return true
            return true;
        }
        //console.log("url from auth =>",state.url);
        this.accountService.currentPageValue(null);
        //console.log(user);
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url }});
        //account/login
        return false;
    }

}
