import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor ,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { SubscriberService } from '@app/services/subscriber.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: SubscriberService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user :any = this.accountService.userValue;
        const isLoggedIn = user && user.access_token;
        
        //console.log(user);
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            
           if(request.method == 'GET') { 
            const params = new HttpParams()
            .set('db_token', user.db_token)
            .set('user_id' , `${user.user_id}`);
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.access_token}` 
                    }, params
                });
           } else  {
            if (request.body instanceof FormData) { 
                const data = {'db_token': `${user.db_token}` , 'user_id' : `${user.user_id}`}
                 request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.access_token}`//,
                        //'Content-Type':  'multipart/form-data;boundary='+Math.random()//'x-www-form-urlencoded'//'multipart/form-data;boundary='+Math.random()   //x-www-form-urlencoded   multipart/data
                        
                    },body :  request.body.append('db_token', `${user.db_token}`) 
                });

                request = request.clone({
                   body :  request.body.append('user_id', `${user.user_id}`) 
                });
            }else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.access_token}`
                    },
                    body : {...request.body , 'db_token': `${user.db_token}` , 'user_id' : `${user.user_id}`  } 
                    
                    //body : request.body.append('db_token', `${user.db_token}`)
                });
            }
                  
            }
        }
        //console.log('check inspector',request);
        return next.handle(request);
    }
}