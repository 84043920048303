import { Directive, AfterContentInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {

  @Input() public autofocus: boolean

  constructor(private elementRef: ElementRef) { }

  public ngAfterContentInit() {
    setTimeout(() =>{
      this.elementRef.nativeElement.focus()
    }, 500)
  }

}
