<div class="login-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-11 signin-bg-img">
        <div class="w-100 float-left">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-6  d-lg-block d-none">
              <div class="logo-dv w-100 float-left">
                <a routerLink="/signin"> <img src="../../../assets/images/logo.png" class="img-fluid" alt="logo"></a>
              </div><!--logo-dv-->
            </div>
          </div><!--row-->

          <div class="row">
            <div class="col-sm-11 col-10 offset-1 col-md-9 offset-md-2 offset-lg-1 col-lg-5 offset-sm-1">
              <div class="login-form w-100  d-flex">
                <div class="w-100 float-left">
                  <div class="logo-dv w-100 float-left pl-0">
                    <div class="row">
                      <div class="col-8 offset-2 mb-5 d-block d-lg-none">
                        <img src="../../../assets/images/logo.png" class="img-fluid w-100 " alt="logo">
                      </div>
                    </div>
                  </div><!--logo-dv-->
                  <div class="form-heading w-100 float-left text-center mb-3">
                    <h1>Reset Password</h1>
                    <p>Please enter new password</p>
                    <h6 class="mt-3">Instructions</h6>
                    <ul>
                      <li>1. Minimum 8 characters.</li>
                      <li>2. A mixture of both uppercase and lowercase letters.</li>
                      <li>3. A mixture of letters and numbers.</li>
                      <li>4. Inclusion of at least one special character.</li>
                    </ul>
                  </div>

                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                      <label for="password">Password</label>
                      <div class="passinpt">
                        <input type="{{ typePassword ? 'text' : 'password' }}" placeholder="Password" formControlName="password" appAutofocus class="form-control" [ngClass]="{ 'is-invalid': (rpFormControl.password.errors && rpFormControl.password.touched) }">
                        <a (click)="togglePassword('password');" *ngIf="typePassword" [ngClass]="{ 'password-type': (rpFormControl.password.errors && rpFormControl.password.touched) }"><img src="assets/images/show-pass.png"></a>
                        <a (click)="togglePassword('password');" *ngIf="!typePassword" [ngClass]="{ 'password-type': (rpFormControl.password.errors && rpFormControl.password.touched) }"><img src="assets/images/hide-pass.png"></a>
                        <div *ngIf="(submitted && rpFormControl.password.errors) || (rpFormControl.password.errors && rpFormControl.password.touched)" class="invalid-feedback">
                          <div *ngIf="rpFormControl.password.errors.required">Password is required</div>
                          <div *ngIf="rpFormControl.password.errors.minlength">Password must be 8 to 20 characters long
                          </div>
                          <div *ngIf="rpFormControl.password.errors.maxlength">Password must be 8 to 20 characters long
                          </div>
                          <div class="error-text" *ngIf="rpFormControl.password.hasError('passwordStrength')">
                            {{rpFormControl.password.errors['passwordStrength']}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="cnf_password">Confirm Password</label>
                      <div class="passinpt">
                        <input type="{{ typeConfimPass ? 'text' : 'password' }}" placeholder="Confirm Password" formControlName="cnf_password" class="form-control" [ngClass]="{ 'is-invalid': (rpFormControl.cnf_password.errors && rpFormControl.cnf_password.touched) }">
                        <a (click)="togglePassword('confimPass');" *ngIf="typeConfimPass" [ngClass]="{ 'password-type': (rpFormControl.cnf_password.errors && rpFormControl.cnf_password.touched) }"><img src="assets/images/show-pass.png"></a>
                        <a (click)="togglePassword('confimPass');" *ngIf="!typeConfimPass" [ngClass]="{ 'password-type': (rpFormControl.cnf_password.errors && rpFormControl.cnf_password.touched) }"><img src="assets/images/hide-pass.png"></a>
                        <div *ngIf="(submitted && rpFormControl.cnf_password.errors) || (rpFormControl.cnf_password.errors && rpFormControl.cnf_password.touched)" class="invalid-feedback">
                            <div *ngIf="rpFormControl.cnf_password.errors.required">Confirm Password is required</div>
                            <div *ngIf="rpFormControl.cnf_password.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button [disabled]="loading" class="btn btn-lg btn-block col-12 blue-button">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div> <!--login-form-->
            </div><!--col-7-->
          </div><!--end-row-->
        </div>
      </div>
    </div><!--row-->
  </div>
</div>
