import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl , AbstractControl ,ValidationErrors , ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriberService } from '@app/services/subscriber.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { CommonService } from '@app/services/common.service';

//function checkOtp(mobileotp,otp) { // here we have the 'passwords' group
  //return mobileotp == otp ? null : { notSame: true }
//}

function checkOtp(otp: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        //console.log(control.value , otp);
        if (control.value !== undefined && (isNaN(control.value) || control.value != otp)) {
            return { 'notmValidOtp': true };
        }
        return null;
    };
}

function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}// end confirmedvalidator


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  cities;   states;  motp; eotp;  otpDetail;
  second: number;
  mobileopttext: any = "Sent Otp";
  enabled = false;
  isMobileOtpMatch = true;

  unsubscribe$ = new Subject()

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private subscriberService: SubscriberService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.getAllStates().pipe(takeUntil(this.unsubscribe$))
    .subscribe(data =>{
        this.states = data;});

    this.form = this.formBuilder.group({
      subscribername: ['', Validators.required],
      ownername: ['', Validators.required],
      gstnumber: ['', Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/) , this.isGstUnique.bind(this)],
      address: ['', Validators.required],
      mobilenumber: ['', [Validators.required ,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)] , this.isPhoneUnique.bind(this) ],
      mobileotp: ['', [Validators.required ,Validators.pattern(/^((\\+91-?)|0)?[0-9]{4}$/) , checkOtp(this.motp) ]],
      emailid: ['', [Validators.required,  Validators.email], this.isEmailUnique.bind(this) ],
      emailotp: ['', [Validators.required ,Validators.pattern(/^((\\+91-?)|0)?[0-9]{4}$/) , checkOtp(this.eotp) ]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: [''],
      packageId:[localStorage.getItem('packageId')],
      password: ['', Validators.required],
      cnf_password: ['', Validators.required],
    }, {
      validator: ConfirmedValidator('password', 'cnf_password')
    });

    this.form.get('mobileotp').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.form.controls['mobileotp'].
      setValidators(Validators.compose([Validators.required,checkOtp(this.motp)]));});

    this.form.get('emailotp').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.form.controls['emailotp'].
      setValidators(Validators.compose([Validators.required,checkOtp(this.eotp)]));});
  }

  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.subscriberService.isEmailRegisterd(control.value).pipe(takeUntil(this.unsubscribe$)).subscribe((data:any) => {
          resolve(null);
        }, () => {
          resolve({ 'isEmailUnique': true });
        });
      }, 1000);
    });
    return q;
  }// end isEmailunique

  isPhoneUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.subscriberService.isPhoneRegisterd(control.value).pipe(takeUntil(this.unsubscribe$)).subscribe((data:any) => {
          resolve(null);
        }, () => {
          resolve({ 'isPhoneUnique': true });
        });
      }, 1000);
    });

    return q;
  }// end isPhoneunique

  isGstUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.subscriberService.isGstRegisterd(control.value).pipe(takeUntil(this.unsubscribe$)).subscribe((data:any) => {
          resolve(null);
        }, () => {
          resolve({ 'isGstUnique': true });
        });
      }, 1000);
    });
    return q;
  }// end isGstunique

  onStateChange(id){
    this.commonService.getAllCitiesByState(id).pipe(takeUntil(this.unsubscribe$))
    .subscribe(data =>{
        this.cities = data;});
  }


  sendOtp(str)
  {
    this.otpDetail = {"otpType"  : str ,"mobilenumber" : this.f.mobilenumber.value  , "otp" : this.eotp }
    if(str == 'mobile')
    {
      this.enabled = true;
      this.second = 60;
      //return true;
      setInterval(() => {
        if(this.second > 0) { this.mobileopttext = this.second--; }
        else                { this.mobileopttext = "Sent Otp"; this.enabled = false; return false }
      },1000);
      this.otpDetail = {"otpType"  : str ,"mobilenumber" : this.f.mobilenumber.value  , "otp" : this.motp }
    }
    //console.log(this.otpDetail);
    this.subscriberService.sendOtp(this.otpDetail)
        .pipe(first(), takeUntil(this.unsubscribe$))
        .subscribe(
            (data : any) => {
              if(data.success)
              {
                console.log(data.otp);
                if(str == 'mobile')   this.motp = data.otp;
                else                  this.eotp = data.otp;
              }
                //console.log(data.id);

            },
            error => {
                console.log(error);
                //this.alertService.error(error);
                this.loading = false;
            });
  }// end sendOtp


   // convenience getter for easy access to form fields
   get f() { return this.form.controls; }


  onSubmit() {
    this.submitted = true;
    //console.log(this.form.value);
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }



    this.subscriberService.registerProspect(this.form.value).pipe(takeUntil(this.unsubscribe$))
        .pipe(first(), takeUntil(this.unsubscribe$))
        .subscribe(
            (data : any) => {
                //console.log(data.id);
                //this.alertService.success('Branch added successfully', { keepAfterRouteChange: true });
                data = JSON.parse(data);
                this.router.navigate(['/payment-process/'+data.id ], { skipLocationChange: true });
            },
            error => {
                console.log(error);
                //this.alertService.error(error);
                this.loading = false;
            });
   }// end function onsubmit

   ngOnDestroy(): void {
       this.unsubscribe$.next()
       this.unsubscribe$.complete()
   }


}
