import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject , Observable, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router'
import { SubscriberService } from '@app/services/subscriber.service';


const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY =  'lastAction';
@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  sessionStart:any = Date.now();

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router,
    private subscriberService:  SubscriberService
    , private http: HttpClient) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
  }

  getTimeOut() {
    return this.http.get(`${environment.apiUrl}/time-out`);
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

@HostListener('window:onload')
  onLoad() {
    this.getTimeOut().subscribe(data =>{})
      // const timer = setInterval(function() {
      //     if (Date.now() - this.sessionStart > 10 * 60 * 1000) {
      //       this.sessionStart = Date.now();
      //     } else if (Date.now() - this.sessionStart > 15 * 60 * 1000) {
      //         clearTimeout(timer);

      //     }
      // }, 1000);
  };





  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout)  {
      localStorage.clear();
      this.subscriberService.logout();
    }
  }

}
