import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductPackageService } from './../../services/product-package.service';

@Component({
  selector: 'app-package-cart',
  templateUrl: './package-cart.component.html',
  styleUrls: ['./package-cart.component.css']
})
export class PackageCartComponent implements OnInit {
  pacakageId:string;
  pacakageType:string;
  packages;
  selectedPackage:any;
  addons : any;
  response : any;
  selectedAddons :any;
  addonsCost :any;
  taxAmt :any;
  subTotalAmt :any;
  totalAmt :any;
  constructor(private route: ActivatedRoute , private ProductPackageService: ProductPackageService) {
    route.params.subscribe(val => {
      this.pacakageId = this.route.snapshot.paramMap.get('id');
      this.pacakageType = this.route.snapshot.paramMap.get('type');
      this.ProductPackageService.getSelectedPackage(this.pacakageId, localStorage.getItem('selectedAddons'), localStorage.getItem('noOfBranches'), localStorage.getItem('noOfMonths'))
              .subscribe(data =>{ 
                  this.response     = data;
                  this.packages     = this.response.packages;
                  this.addons       = this.response.addons;
                  this.addonsCost   = this.response.addonsCost;
                  this.taxAmt       = this.response.taxAmt;
                  this.subTotalAmt  = this.response.subTotalAmt;
                  this.totalAmt     = this.response.totalAmt;
                  localStorage.setItem('selectedAddons', this.response.selectedAddons);

                  /* this.packages = data;
                  this.packages = JSON.parse(this.packages); */
                  for(let i=0;i < this.packages.length;i++)
                  {
                    if(this.packages[i].id == this.pacakageId)
                    {
                      this.selectedPackage = this.packages[i]; 
                    }
                  } //  end for
                
                });
      });
   }

  ngOnInit(): void {
                 
  }

  
  

}
