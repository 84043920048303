import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNull'
})
export class ReplaceNullPipe implements PipeTransform {

  transform(value: any, replaceData: string = 'N/A' ): any {

    if(value === null || value === '' || value === 'null'){
      return replaceData
    }
    return value
  }
}
