import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductPackagesComponent } from './component/product-packages/product-packages.component';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { PackageCartComponent } from './component/package-cart/package-cart.component';
import { RegisterSubscriberComponent } from './component/register-subscriber/register-subscriber.component';
import { DynamicHostDirective } from './component/dynamic-host.directive';
import { DynamicDetailComponent } from './component/dynamic-content.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { PaymenyProcessComponent } from '@app/component/payment-process/payment-process.component';
import { PaymentSuccessComponent } from './component/payment-success/payment-success.component';
import { JwtInterceptor, ErrorInterceptor } from './helper';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestThemeComponent } from './component/test-theme/test-theme.component';
import { TestNewComponentComponent } from './component/test-new-component/test-new-component.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from './shared/shared.module';


//import { CanLoadGuard } from './helper';
@NgModule({
  declarations: [
    AppComponent,
    ProductPackagesComponent,
    PackageCartComponent,
    RegisterSubscriberComponent,
    DynamicHostDirective,
    DynamicDetailComponent,
    LoginComponent,
    RegisterComponent,
    PaymenyProcessComponent,
    PaymentSuccessComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TestThemeComponent,
    TestNewComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule ,
    FormsModule,
    TooltipModule,
    NgSelectModule,
    DragDropModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatAutocompleteModule,
    SharedModule,
    // MatDialog,
    ToastrModule.forRoot()

  ],
  exports: [
    DragDropModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
