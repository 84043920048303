import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DynamicHostDirective } from './dynamic-host.directive';
import { DynamicContentService } from '@app/services/dynamic-content.service';

import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dynamic-content-container',
  template: `
    <ng-template DynamicHost></ng-template>
  `
})
export class DynamicDetailComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicHostDirective, { static: true })
  profileHost: DynamicHostDirective;
  private destroySubject = new Subject();

  constructor(private dynamicContentService: DynamicContentService) {}

  ngOnInit() {
    const viewContainerRef = this.profileHost.viewContainerRef;

    this.dynamicContentService.contenttype$
      .pipe(
        takeUntil(this.destroySubject),
        mergeMap(modaltype =>
          this.dynamicContentService.loadComponent(viewContainerRef, modaltype)
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
