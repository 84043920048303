<router-outlet></router-outlet>


<!-- <fa-icon [icon]="faCoffee"></fa-icon> -->

<!--<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let movie of movies" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      {{movie}}
    </div>
  </div>-->