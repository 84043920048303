import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError,tap } from 'rxjs/operators';

import { SubscriberService } from '@app/services/subscriber.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private accountService: SubscriberService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          tap(event => { 
            if (event instanceof HttpResponse) {
                let response = event.body;
                if(response.status == "601" && !this.isIgnorePath()){ console.log("601");
                    this.accountService.logout();    
                }
            }
          }),
            catchError(err => {
            if (err.status === 401) {  console.log(err.status);
                // auto logout if 401 response returned from api
                //this.accountService.logout();
            }
            
            const error = err.error.message || err.statusText;
            return throwError(error);
            })
        );

        return next.handle(request).pipe(
            catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.accountService.logout();
            }
            
            const error = err.error.message || err.statusText;
            return throwError(error);
            })
        )
    }

    public isIgnorePath(): boolean {
       const currentLocation = window.location.href;
       const ignorePath = ['forgot-password', 'reset-password','signin'];
       let ignore = false;
       ignorePath.forEach(x => {
        if(currentLocation.includes(x)) {
            ignore = true;
        }
       });
       return ignore; 
    } 
}