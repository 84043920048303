<div class="container">
	<div class="row text-center">
        <div class="col-sm-6 col-sm-offset-3">
        <br><br> <h2 style="color:#0fad00">CONGRATULATIONS!</h2>
        <br><h4>PAYMENT SUCCESSFUL</h4><br> 
        <p>WELCOME TO <strong style="font-size: 34px;">TRANSIFIED</strong> FAMILY!</p> 

        <a routerLink='/signin' skipLocationChange replaceUrl class="btn btn-success">GO TO LOGIN</a>
    	<br><br>
        </div>
        
	</div>
</div>