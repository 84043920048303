<div class="login-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-11 signin-bg-img">
        <div class="w-100 float-left">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-6  d-lg-block d-none">
              <div class="logo-dv w-100 float-left">
                <a routerLink="../signin"> <img src="../../../assets/images/logo.png" class="img-fluid" alt="logo"></a>
              </div><!--logo-dv-->
            </div>
          </div><!--row-->
          <div class="row">
            <div class="col-sm-11 col-10 offset-1 col-md-9 offset-md-2 offset-lg-1 col-lg-5 offset-sm-1">
              <div class="login-form w-100  d-flex">
                <div class="w-100 float-left">
                  <div class="logo-dv w-100 float-left pl-0">
                    <div class="row">
                      <div class="col-8 offset-2 mb-5 d-block d-lg-none">
                        <img src="../../../assets/images/logo.png" class="img-fluid w-100 " alt="logo">
                      </div>
                    </div>
                  </div><!--logo-dv-->
                  <div class="form-heading w-100 float-left text-center mb-3" [hidden]="hideShowForm1">
                    <h1>Forgot Password</h1>
                    <p>Enter registered email address to reset password<p>
                  </div>
                  <div class="form-heading w-100 float-left text-center mb-3" [hidden]="hideShowForm2">
                    <h1>Forgot Password</h1>
                    <p>Please enter the verification code received on your registered email address<p>
                  </div>
                  <form [formGroup]="forgetForm" (ngSubmit)="onSubmit()" [hidden]="hideShowForm1">
                    <div class="form-group">
                      <label for="username">Email Address</label>
                      <input type="text" formControlName="email" appAutofocus class="form-control" [ngClass]="{ 'is-invalid': (forgetFormControl.email.errors && forgetFormControl.email.touched) }" />
                      <div *ngIf="(forgetFormControl.email.errors && forgetFormControl.email.touched)" class="invalid-feedback">
                        <div *ngIf="forgetFormControl.email.errors.required">Email is required</div>
                        <div *ngIf="forgetFormControl.email.errors.pattern">Email must be a valid email address</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button [disabled]="!forgetForm.valid" class="btn btn-lg btn-block col-12 blue-button" >
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                        </button>
                    </div>
                  </form>
                  <form [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()" [hidden]="hideShowForm2">
                    <div class="form-group">
                      <label for="verificationCode">Verification Code</label>
                      <input type="text" placeholder="Verification Code" appAutofocus formControlName="verificationCode" class="form-control" [ngClass]="{ 'is-invalid': (otpFormControl.verificationCode.errors && otpFormControl.verificationCode.touched) }" (keypress)="onlyNumberKey($event)">
                      <div *ngIf="(vsubmitted && otpFormControl.verificationCode.errors) || (otpFormControl.verificationCode.errors && otpFormControl.verificationCode.touched)" class="invalid-feedback">
                        <div *ngIf="otpFormControl.verificationCode.errors.required">Verification Code is required</div>
                        <div *ngIf="otpFormControl.verificationCode.errors.minlength">Verification Code length must be 4-6 digits</div>
                        <div *ngIf="otpFormControl.verificationCode.errors.maxlength">Verification Code length must be 4-6 digits</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button [disabled]="!otpForm.valid" class="btn btn-lg btn-block col-12 blue-button" >
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                        </button>
                    </div>
                  </form>
                </div>
              </div><!--login-form-->
            </div><!--col-7-->

          </div><!--end-row-->
        </div>
      </div>
    </div><!--row-->
  </div>
</div>
