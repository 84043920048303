<div class="px-4 px-lg-0">
  <div class="pb-5">
    <div class="container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
         <div class="input-group mb-4 ">&nbsp;</div> 
         <div class="input-group mb-4 ">&nbsp;</div> 
          <div class="p-4">
              <button type="submit" class="btn btn-dark rounded-pill py-2 btn-block">Pay Now</button>
          </div>
      </form>
    </div>
  </div>
</div>