import { AutofocusDirective } from './../directives/autofocus.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaceNullPipe } from './pipe/replace-null.pipe';
import { TwodigitdeciDirective } from '@app/directives/twodigitdeci.directive';




@NgModule({
  declarations: [AutofocusDirective, ReplaceNullPipe, TwodigitdeciDirective],
  imports: [
    CommonModule
  ],
  exports:[
    AutofocusDirective,
    ReplaceNullPipe,
    TwodigitdeciDirective
  ]
})
export class SharedModule { }
