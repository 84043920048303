<div class="px-4 px-lg-0">
  <div class="pb-5">
    <div class="container custom_card">
      <div class="row">

        <!-- <div class="col-lg-6">

          <p class="font-italic mb-4">
            <strong><a routerLink='/' skipLocationChange replaceUrl class="button">Back</a></strong>
          </p>

          <div class="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">All Packages</div>
          <div class="p-4" *ngFor="let package of packages" routerLink='/cart/{{package.id}}' skipLocationChange
            replaceUrl>
            <p class="font-italic mb-4"><strong>{{package.packageName}}</strong> {{package.cost}}Rs
              {{package.costDetail}}<br />
              <li *ngFor="let x of package.description.split('\n')">{{x}}</li>
            </p>
          </div>

          <div class="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">All Addons</div>
          <ul class="addon">
            <li *ngFor="let addon of addons; let i = index">{{addon.addonName}}</li>
          </ul>

        </div>

        <div class="col-lg-6">
          <div class="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">Order summary </div>
          <div class="p-4">
            <p class="font-italic mb-4"><strong>{{selectedPackage?.packageName}}</strong></p>
            <ul class="list-unstyled mb-4">
              <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Addons</strong>
                <ul class="addon">
                  <li *ngFor="let addon of addons; let i = index">{{addon.addonName}} <br> <small>
                      {{addon.costDetail}}</small> <strong>{{addon.cost}}</strong>
                    <br> <strong>{{addonsCost}}</strong>
                  </li>
                </ul>
              </li>
              <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Order Subtotal
                </strong><strong>{{subTotalAmt}}</strong></li>
              <li class="d-flex justify-content-between py-3 border-bottom"><strong
                  class="text-muted">Tax</strong><strong>{{taxAmt}}</strong></li>
              <li class="d-flex justify-content-between py-3 border-bottom"><strong class="text-muted">Total</strong>
                <h5 class="font-weight-bold">{{totalAmt}}</h5>
              </li>
            </ul><a routerLink='/signup/{{selectedPackage?.id}}/{{pacakageType}}' skipLocationChange replaceUrl
              class="btn btn-dark rounded-pill py-2 btn-block">Procceed to checkout</a>
          </div>
        </div> -->


        <div class="col-lg-6">
          <div class="product_card mob_card bg-white borderRd my_shadow">
            <div class="product_card_header list_pro_header">
              <div class="card_heading">
                <p class="order_amount"><a routerLink='/' skipLocationChange replaceUrl class="button">Back</a></p>
              </div>
            </div>
            <div>
              <div class="product_card_detail pro_deta">
                <div class="product_details">
                  <div class="product_name">
                    <a class="order_amount">All Packages</a>
                    <div class="p-4" *ngFor="let package of packages" routerLink='/cart/{{package.id}}'
                      skipLocationChange replaceUrl>
                      <p class="order_amount mb-4"><strong>{{package.packageName}}</strong> {{package.cost}}Rs
                        {{package.costDetail}}<br />
                        <li *ngFor="let x of package.description.split('\n')">{{x}}</li>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="product_card_detail pro_deta">
                <div class="product_details">
                  <div class="product_name">
                    <a class="order_amount">All Addons</a>
                    <p *ngFor="let addon of addons; let i = index" class="custom_addon"> * {{addon.addonName}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-6">
          <div class="product_card_order mob_card bg-white borderRd my_shadow">
            <div class="product_card_header list_pro_header">
              <div class="card_heading">
                <p class="order_amount">Order summary</p>
              </div>
            </div>
            <div>
              <div class="product_card_detail pro_deta">
                <a class="order_amount">All Addons</a>
                <div class="row">
                  <div class="product_name col-lg-9">
                    <p class="custom_addon_detail" *ngFor="let addon of addons; let i = index">{{addon.addonName}}
                      {{addon.costDetail}} {{addon.cost}}</p>
                  </div>
                  <div class="col-lg-3" style="text-align: end;">
                    <p class="order_amount" *ngFor="let addon of addons; let i = index">{{addonsCost}}</p>
                  </div>
                </div>
              </div>

              <div class="product_card_detail_order pro_deta">
                <div class="row">
                  <div class="product_name col-lg-9">
                    <p class="order_amount">Order Subtotal</p>
                  </div>
                  <div class="col-lg-3" style="text-align: end;">
                    <p class="order_amount">{{subTotalAmt}}</p>
                  </div>
                </div>
              </div>

              <div class="product_card_detail_order pro_deta">
                <div class="row">
                  <div class="product_name col-lg-9">
                    <p class="order_amount">Tax</p>
                  </div>
                  <div class="col-lg-3" style="text-align: end;">
                    <p class="order_amount">{{taxAmt}}</p>
                  </div>
                </div>
              </div>

              <div class="product_card_detail_order pro_deta">
                <div class="row">
                  <div class="product_name col-lg-9">
                    <p class="order_amount">Total Amount</p>
                  </div>
                  <div class="col-lg-3" style="text-align: end;">
                    <p class="order_amount">{{totalAmt}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="product_card_detail_order pro_deta">
              <h2 style="text-align:center">
                <a routerLink='/signup/{{selectedPackage?.id}}/{{pacakageType}}' skipLocationChange replaceUrl
                 class="custom_checkout_button">Procees to Checkout</a>
              </h2>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>